import * as React from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import MainLayout from '../layouts/main-layout/main-layout';
import { graphql } from 'gatsby';
import ParsedContent from '../components/parsed-content/parsed-content';

import * as styles from '../styles/pages/about-us.module.scss';

const AboutUsPage = ({ data }) => {
	const intl = useIntl();

	return (
		<MainLayout
			seo={{
				title: intl.formatMessage({
					id: 'about-us-page-title',
					defaultMessage: 'About Us',
				}),
				description: data?.api?.seo?.siteDescription,
			}}
		>
			<section className={styles.aboutCompanySection}>
				<h1 className="lower">
					{intl.formatMessage({
						id: 'about-us-page-about-company-section-title',
						defaultMessage: 'About company',
					})}
				</h1>

				<ParsedContent html={data?.api?.aboutUs?.aboutUsText} />
			</section>

			<section className={styles.teamSection}>
				<div className={styles.flexContainer}>
					<div className={styles.aboutTeam}>
						<h2 className="lower">
							{intl.formatMessage({
								id: 'about-us-page-about-team-title',
								defaultMessage: 'Design team',
							})}
						</h2>

						<ParsedContent html={data?.api?.aboutUs?.teamText} />
					</div>

					<div className={styles.aboutHeadArchitect}>
						<h2 className="lower">
							{intl.formatMessage({
								id: 'about-us-page-about-head-architect-title',
								defaultMessage: 'Head architect',
							})}
						</h2>

						<ParsedContent html={data?.api?.aboutUs?.headArchitectText} />
					</div>
				</div>
			</section>
		</MainLayout>
	);
};

export const query = graphql`
	query($language: String!) {
		api {
			seo: seoSingleton(lang: $language) {
				siteDescription
			}

			aboutUs: aboutUsSingleton(lang: $language) {
				aboutUsText
				teamText
				headArchitectText
			}
		}
	}
`;

export default AboutUsPage;
