import * as React from 'react';
import Header from '../../components/header/header';
import Seo from '../../components/seo/seo';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Footer from '../../components/footer/footer';

import * as styles from './main-layout.module.scss';

const MainLayout = ({ seo, lightHeader, avoidHeader, useH1InHeader, children }) => {
	return (
		<React.Fragment>
			<Seo {...seo} />
			<Header useH1={useH1InHeader} light={lightHeader} />

			<main
				className={classNames({
					[styles.main]: true,
					[styles.avoidHeader]: avoidHeader,
				})}
			>
				{children}
			</main>

			<Footer />
		</React.Fragment>
	);
};

MainLayout.defaultProps = {
	seo: {},
	lightHeader: false,
	avoidHeader: true,
	useH1InHeader: false,
};

MainLayout.propTypes = {
	seo: PropTypes.shape(Seo.propTypes),
	lightHeader: PropTypes.bool,
	avoidHeader: PropTypes.bool,
	useH1InHeader: PropTypes.bool,
};

export default MainLayout;
